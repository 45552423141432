/* This file contains all the constants for colors and font styles */

$body-font: "Zen Maru Gothic";
$sans-font: "Zen Maru Gothic";
$header-font: "Zen Maru Gothic";
$code-font: "SF Mono", ui-monospace, monospace;
$url-font: "Lucida Console", "Lucida Sans Typewriter", Monaco, "Bitstream Vera Sans Mono", monospace;
$text-color: #111;
$bg-color: #f9f9f9;
$contrast-color: #111;
$border-color: #333333;
$link-style: underline; // choices are 'color' or 'underline'. Color option styles links with $contrast-color set above



