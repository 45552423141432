@import "_settings"

@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&family=Shippori+Mincho:wght@400;500&display=swap')

.flex
  display: flex

.column
  flex-direction: column

.grow
  flex-grow: 1
  min-height: 0

.wrap
  flex-wrap: wrap

.email b
  display: none

article
  h1
    font-family: $header-font
    font-weight: 500
  h2, h3, h4
    border-top: 1px solid $border-color
    padding-top: 1rem
    font-family: $header-font
    font-weight: 500
  &> p
    font-family: "Shippori Mincho", serif
    font-weight: 400
  p > a[href$=jpg], p > a[href$=png]
    background: none
    text-shadow: none
  &.group
    h2, h3, h4
      a
        color: unset
      a:hover
        border-bottom: 1px solid
    span.marginnote
      img
        box-shadow: 4px 4px 0px 1px black
        border: 1px solid

code, .code
  font-family: ui-monospace, monospace
  display: block
  overflow: auto

  >
    a:last-of-type::after, span:last-of-type::after, p:last-of-type::after
      content: "_"
      animation: blinker 1.1s cubic-bezier(1, 0, 0, 1) infinite

.mono
  font-family: ui-monospace, monospace !important
  font-weight: 400 !important

img
  max-width: 100%

@keyframes blinker
  from
    opacity: 1

  to
    opacity: 0

nav
  position: relative
  margin-top: 0
  margin-bottom: 1rem
  display: flex

  #home
    flex-shrink: 0
    flex-grow: 0

  a
    font-weight: 400
    font-size: 1.5rem
    text-decoration: none
    color: $text-color
    height: fit-content
    min-width: 0
    flex-grow: 1
    li
      margin-top: 5px
      list-style-type: none
      padding-bottom: 2rem
      text-align: right
      position: relative
      &::after
        content: ""
        background: $text-color
        transition: width 0.25s
        width: 100%
        height: 1px
        position: absolute
        bottom: 0
        right: 0
      &:hover
        &::after
          width: 50%
      &.current::after
        width: 50%
      &:nth-child(2):not(nav li)
        border-top: 1px solid #685d5d
        padding-top: 0.2rem

body > footer
  display: flex
  max-height: 100px

  a
    text-decoration: none
    background: unset
    text-shadow: unset

  > p
    &:not(.mono)
      margin-top: 9px
      margin-bottom: 0
      font-size: 0.9rem
      flex-shrink: 0

    &.mono
      margin-top: 9px
      margin-bottom: 0
      padding-bottom: 10px
      text-align: right !important
      width: 100%
      font-size: 0.8rem

    > a > img
      height: 1.5rem
      display: inline-block
      vertical-align: top

blockquote > p
    font-family: $header-font
    font-weight: 500
    font-size: 1.75rem

.post-block
  margin-top: .5rem
  margin-bottom: 2rem

  a
    padding: 0
    background: 0 0
    color: $text-color

  h2
    border-top: 0 solid transparent
    margin: 0
    width: 100%
    font-size: 1rem
    padding-top: 0
    line-height: 1
    position: relative
    background-color: $bg-color
    z-index: 1
    &::after
      content: ""
      height: 1px
      width: 0%
      position: absolute
      background: $text-color
      transition: width 0.25s ease
      right: 1rem
      top: 50%
      z-index: -1
    a:hover
      color: $contrast-color
    &:hover
      &::after
        width: calc(100% - 1rem)

    a
      background: #f9f9f9
      z-index: 2
      padding-right: 0.5rem
    a:hover
      text-decoration: none

  p
    font-size: 0.9rem
    margin: 0
    width: 50%

  .title
    display: flex
    justify-content: space-between

  .desc
    padding-top: 0.25rem
    line-height: 1.25

time
  font-size: .75rem
  letter-spacing: 0
  color: #000
  flex-shrink: 0
  align-self: center
  font-family: ui-monospace, monospace

a.project-link
  background: 0 0
  color: $text-color
  cursor: default
  flex-grow: 1
  max-width: calc(50% - 3rem)
  background: white
  border: 4px double black
  padding: 0.5rem
  z-index: 50

  &:hover
    text-decoration: none

.project-block

  p
    width: 100%
    margin: 0
    padding-top: 0.25rem

  div.post-image
    height: 300px
    width: 100%
    overflow: hidden
    cursor: pointer

    img.post-image
      width: 100%
      object-position: center
      object-fit: cover
      height: 300px

  h2
    cursor: pointer
    font-family: sans-serif
    border-top: 0 solid transparent
    margin: 0
    width: 100%
    padding: 0.25rem 0 0 0

    a:hover
      text-decoration: none

div#history
  overflow-y: scroll
  overflow-x: hidden
  max-height: 500px

  h5
    display: flex
    margin: 1rem 0
    align-items: center
    width: 100%
    text-align: center
    font-size: 0.7rem
    font-weight: normal
    color: $contrast-color

    &#depart
      color: red
      opacity: 0.75

      &::after
        border-bottom: 1px solid red
        content: ''
        flex: 1
        opacity: 0.75

    &::after
      content: ''
      flex: 1
      border-bottom: 1px solid $contrast-color

    &:not(:empty)
      &::before
        margin-right: .50em

      &::after
        margin-left: .50em

    a
      background: none !important
      text-decoration: underline
      color: inherit !important
      margin-left: 3px

#history ul
  list-style-type: none
  font-size: 0.8rem
  margin: 0
  width: 100%
  -webkit-padding-start: unset
  -webkit-padding-end: unset

  li
    margin: 1rem 0

.sidenote, .marginnote
  padding-bottom: 1rem
  border: 1px solid transparent
  transition: 0.25s all ease-in-out
  &::before
    font-family: sans-serif
  &::after
    transition: width 0.25s
    content: ""
    background-color: black
    height: 1px
    position: absolute
    bottom: 0
    left: 0
    width: 0%
  &:hover
    &:before
      color: $text-color
    &::after
      width: 100%
// mobile
@media all and (max-width: 600px)
  body > footer
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: justify

    > p
      padding-right: 0 !important
      display: flex
      align-items: center
      justify-content: space-between
    > p.mono
      display: flex
      justify-content: space-evenly

  #color
    display: none
  div#history
    width: initial
    margin-top: initial
    margin-left: initial

  a.project-link
    max-width: 100%
    
  #mobile-homepage-head
    transform: scale(1.1) !important

body
  overflow-y: auto
  overflow-x: hidden